<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('subscriptions.form.CreateASubscription')"
          :description="$t('subscriptions.form.FromHereYouCanCreateANewSubscription')"
          v-if="$route.name == 'subscriptionsCreate'"
        />
        <addHeader
          :title="$t('subscriptions.form.EditSubscription')"
          :description="
            $t('subscriptions.form.FromHereYouCanModifyYourSubscription')
          "
          v-if="$route.name == 'subscriptionsEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('subscriptions.form.basicInformation')"
            :description="$t('subscriptions.form.BasicInformationToSubscribe')"
            noLine="true"
          />
          <fileInput
            :title="$t('subscriptions.form.subscriptionImage')"
            v-model="item.image"
            :image="item.image"
            :path="'subscriptions'"
            :hasErorr="errors.image"
            :error="$t('allerts.ChooseASuitableFile')"
            :col="'col-12'"
          />
          <formInput
            :title="$t('subscriptions.form.SubscriptionName')"
            v-model="item.name"
            :hasErorr="errors.name"
            :error="$t('allerts.thisFieldIsRequired')"
            maxlength="255"
          />
          <monyInput
            :title="$t('subscriptions.form.SubscriptionPrice')"
            v-model="item.price"
          />
          <connectionInput
            :title="$t('subscriptions.form.taxRate')"
            :group="$t('subscriptions.form.rates')"
            v-model="item.tax_id"
            :hasAdd="true"
            :values="$database.taxes"
            :open="'addTaxe'"
          />
          <connectionInput
            :title="$t('subscriptions.form.DiscountRate')"
            :group="$t('subscriptions.form.DiscountRates')"
            v-model="item.discount_id"
            :hasAdd="true"
            :values="$database.discounts"
            :open="'addDiscount'"
          />
          <formInput
            :title="$t('subscriptions.form.SubscriptionPeriod')"
            type="number"
            v-model="item.day"
            :hasErorr="errors.day"
            maxlength="255"
          />
          <checkInput :title="$t('subscriptions.form.theStatus')" :value="item.stat" v-model="item.stat"/>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'subscriptionsCreate' && !$parent.stopEdit"
          >
            {{ $t('subscriptions.form.CreateASubscription') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'subscriptionsEdit' && !$parent.stopEdit"
          >
            {{ $t('subscriptions.form.EditSubscription') }}
          </a>
        </div>
      </div>
    </div>
    <addTaxe v-if="model == 'addTaxe'"/>
    <addDiscount v-if="model == 'addDiscount'"/>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import fileInput from "../elements/add/form/fileInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";

import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";


import addTaxe from "./components/addTaxe.vue";
import addDiscount from "./components/addDiscount.vue";
import loading from "../elements/add/loading.vue";

export default {
  data() {
    return {
      path: "/subscriptions",
      item: {
        stat: 1,
        name: "",
        tax_id: 0,
        discount_id: 0,
      },
      errors: {
        name: false,
        price: false
      },
      model: false,
      disabled: true
    };
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.$parent.stopEdit = false;
          this.item = response.data;
        });
    },
    saveItem() {
      var error = 0;
      if (typeof this.item.name === "undefined" || this.item.name == "") {
        error = 1;
        this.errors.name = true;
      }
      if (typeof this.item.barcode !== "undefined" && this.item.barcode != "" && this.item.barcode != null) {
        var barcode = this.$database.subscriptions.find(el => el.barcode == this.item.barcode && el.id != this.item.id)
        if (barcode) {
          error = 1;
          this.errors.barcode = true;
        }
      }
      if (error == 0) {
        if (this.$route.name == "subscriptionsCreate") {
          this.$parent.aletText = this.$t('allerts.productSuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          this.$router.go(-1);
        } else if (this.$route.name == "subscriptionsEdit") {
          this.$parent.stopEdit = true;
          this.item._method = "PUT";
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText = this.$t('allerts.productHasBeenModifiedSuccessfully');
              this.$parent.alertType = "success";
              this.$router.go(-1);
              return response;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  },
  mounted() {
    this.$updateDatabase([
      "discounts",
      "taxes",
    ]);

    if (this.$route.name == "subscriptionsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    } 
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  components: {
    formInput,
    fileInput,
    dvider,
    addHeader,
    formTextarea,
    connectionInput,
    monyInput,
    addTaxe,
    addDiscount,
    loading,
    checkInput
  },
};
</script>
